import React from 'react';

import AppliedFilter from './AppliedFilter';

interface Props {
  appliedContentType: string;
  allContentTypes: AllContentTypes[];
  onClear: () => void;
}

interface AllContentTypes {
  label: string;
  value: string;
}

const ContentType = ({
  appliedContentType: appliedContentType,
  allContentTypes: allContentTypes,
  onClear,
}: Props) => {
  const matchedContentType = allContentTypes.find(
    (st) => st.value === appliedContentType
  );
  const label = matchedContentType ? matchedContentType.label : 'All';

  return (
    <AppliedFilter
      secondaryClassName={label.split(' ').join('')}
      label={label}
      onClear={onClear}
    />
  );
};

export default ContentType;
