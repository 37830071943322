import { builder } from '@builder.io/react';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Telemetry } from '@videoblocks/kafka-rest-client';
import ProminentSearch from '@videoblocks/shared-components/dist/mjs/components/Search/ProminentSearch';
import { searchOrigins } from '@videoblocks/shared-components/dist/mjs/components/Shared/constants';
import { SearchFilterContentTypes } from '@videoblocks/shared-components/dist/mjs/components/Shared/enums';

import { audioSetFiltersChanged } from '../../../Audio/actions/AudioActions';
import {
  selectAuthPermissions,
  selectHasAnySubscription,
} from '../../../auth/AuthSelectors';
import deferToNextFrame from '../../Shared/utils/deferToNextFrame';
import {
  clearSuggestionsQuery,
  isSearchAppLoaded,
  setLoadingForSearch,
  updateSearchOptionsAndRefresh,
  setSearchOptions,
} from '../actions/SearchActions';
import { ProminentSearchOptionsContext } from '../components/HomepageProminentSearch';
import SearchFilterOptions from '../entities/SearchFilterOptions';
import {
  selectContentType,
  selectIsEnterpriseMember,
  selectSearchFilterOptions,
} from '../selectors/searchSelectors';
import {
  encodeSearchTerm,
  fetchTypeahead,
  getDecodedSearchTerm,
} from '../utils/searchUtils';
import { SelectedSearchFilterOptions } from './MenuContainerInterfaces';

interface ProminentSearchProps {
  showDropDown?: boolean;
  showContentToggles?: boolean;
  alignment?: 'left' | 'center';
  useNewSearchUI?: boolean;
}

function ProminentSearchContainer({
  showDropDown = true,
  showContentToggles = true,
  alignment = 'left',
  useNewSearchUI = false,
}: ProminentSearchProps) {
  const dispatch = useDispatch<any>();

  const selectedSearchFilterOptions = useSelector(selectSearchFilterOptions);
  const userHasAnySubscription = useSelector(selectHasAnySubscription);
  const isEnterpriseMember = useSelector(selectIsEnterpriseMember);
  const { pproPluginEnabled } = useSelector(selectAuthPermissions);
  const contentTypeFromPrevious = useSelector(selectContentType);

  const prominentSearchOptions = React.useContext(
    ProminentSearchOptionsContext
  );

  const { contentType = '', searchTerm = '' } = selectedSearchFilterOptions;
  const decodedSearchTerm = getDecodedSearchTerm(searchTerm);

  const constructSearchFilterOptions = useCallback(
    (
      contentType,
      searchTerm,
      searchOrigin = searchOrigins.SEARCH_BAR
    ): Partial<SelectedSearchFilterOptions> => {
      const contentClass =
        SearchFilterOptions.getContentClassFromContentType(contentType);
      return {
        contentClass,
        contentType,
        isPagination: false,
        page: 1,
        searchOrigin,
        searchTerm,
        similarTo: null,
        searchSimilarTitle: null,
      };
    },
    []
  );

  const handleDropdownChange = useCallback(
    (contentType) => {
      // This fixes https://videoblocks.atlassian.net/browse/LNL-227
      if (showDropDown) return;
      const newSelectedSearchFilterOptions = selectedSearchFilterOptions.update(
        constructSearchFilterOptions(contentType, decodedSearchTerm)
      );
      dispatch(setSearchOptions(newSelectedSearchFilterOptions));
    },
    [
      dispatch,
      showDropDown,
      selectedSearchFilterOptions,
      decodedSearchTerm,
      constructSearchFilterOptions,
    ]
  );

  const handleSubmit = useCallback(
    (contentType: string, searchTerm: string, searchOrigin: string) => {
      dispatch(clearSuggestionsQuery());
      dispatch(setLoadingForSearch(true));

      // Defer work that might slow down more important UI updates.
      deferToNextFrame(() => {
        dispatch(audioSetFiltersChanged(true));
      });

      deferToNextFrame(() => {
        builder.init('e7fb3d1e4da14573bd2a1edb7bfee5f1');
        builder.track('search');
      });
      deferToNextFrame(() => {
        const encodedSearchTerm = encodeSearchTerm(searchTerm);
        const newSelectedSearchFilterOptions =
          selectedSearchFilterOptions.update(
            constructSearchFilterOptions(
              contentType,
              encodedSearchTerm,
              searchOrigin
            )
          );
        dispatch(updateSearchOptionsAndRefresh(newSelectedSearchFilterOptions));
      });
    },
    [dispatch, selectedSearchFilterOptions, constructSearchFilterOptions]
  );

  return (
    <ProminentSearch
      contentType={contentType as SearchFilterContentTypes}
      onDropdownChange={handleDropdownChange}
      onSubmit={handleSubmit}
      searchTerm={decodedSearchTerm}
      userHasAnySubscription={userHasAnySubscription}
      fetchTypeahead={fetchTypeahead}
      isEnterpriseMember={isEnterpriseMember}
      pproPluginEnabled={pproPluginEnabled}
      isSearchAppLoaded={isSearchAppLoaded}
      incrementTelemetry={Telemetry.increment}
      imagesUrl={__ASSETS_COMMON_IMAGES_URL__}
      prominentSearchOptions={prominentSearchOptions}
      contentTypeFromPrevious={contentTypeFromPrevious}
      showDropDown={showDropDown}
      showContentToggles={showContentToggles}
      useNewSearchUI={useNewSearchUI}
      alignment={alignment}
    />
  );
}

export default ProminentSearchContainer;
