import React from 'react';

import { ChevronDown, ChevronUp } from '@videoblocks/react-icons';

interface Props {
  isCollapsed: boolean;
  invalidSelectionMessage: string;
}

const ArrowIcon = ({ isCollapsed = false, invalidSelectionMessage }: Props) => {
  if (invalidSelectionMessage) {
    return null;
  }

  if (isCollapsed) {
    return <ChevronDown className="w-3 h-3 m-1.5" />;
  } else {
    return <ChevronUp className="w-3 h-3 m-1.5" />;
  }
};

export default ArrowIcon;
