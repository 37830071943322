import React from 'react';

import Close from '@videoblocks/react-icons/Close';

import { logClickEvent } from '../../containers/appliedFilters/AppliedFiltersUtils';

interface Props {
  label: string;
  onClear: () => void;
  secondaryClassName?: string;
}

const AppliedFilter = ({ label, onClear, secondaryClassName }: Props) => {
  const secondaryClass = `applied-filter-${secondaryClassName || label}`;

  return (
    <div className={`applied-filter ${secondaryClass}`} key={label}>
      <span>{label}</span>
      <div
        tabIndex={0}
        aria-label={`Remove ${label} filter`}
        role="button"
        className="close-button"
        onKeyPress={() => {
          onClear();
          logClickEvent();
        }}
        onClick={() => {
          onClear();
          logClickEvent();
        }}
      >
        <Close
          className="w-2 h-full"
          style={{ stroke: 'black', strokeWidth: 1 }}
        />
      </div>
    </div>
  );
};

export default AppliedFilter;
