import { connect } from 'react-redux';

import { searchOrigins } from '../../Shared/constants';
import { updateSearchOptionsAndRefresh } from '../actions/SearchActions';
import AbstractSearchResultsContainer, {
  mapStateToProps,
} from './AbstractSearchResultsContainer';

class SearchResultsContainer extends AbstractSearchResultsContainer {
  getCurrentStockItems() {
    return this.props.stockItems;
  }

  goToPage = (newPageNo) => {
    const newSearchOptions = this.props.selectedSearchFilterOptions.update({
      page: newPageNo,
      loadMore: false,
      searchOrigin: searchOrigins.PAGINATION,
      isPagination: true,
    });

    this.scrollToTop();

    this.props.dispatch(updateSearchOptionsAndRefresh(newSearchOptions));
  };
}

export default connect(mapStateToProps)(SearchResultsContainer);
