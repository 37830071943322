import React from 'react';
import { twMerge } from 'tailwind-merge';

import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@videoblocks/react-icons';

type Props = {
  isOpen: boolean;
  closeSideMenu: () => void;
  showSideMenu: () => void;
  clearFilters: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
};

export default function ClearFilters({
  isOpen,
  closeSideMenu,
  showSideMenu,
  clearFilters,
}: Props) {
  const clearFilterClass = twMerge(
    'flex justify-between items-center h-20 pr-2 pl-4 border-t-0 border-r-0 border-b border-solid border-gray-300',
    !isOpen && 'border-none pl-0'
  );

  return (
    <div className={clearFilterClass}>
      <span
        role="button"
        tabIndex={0}
        className="clear-filters font-normal text-sm cursor-pointer"
        onClick={clearFilters}
        onKeyDown={clearFilters}
      >
        Clear Filters
      </span>
      <div>
        <div className="toggleContainer-text">Close</div>
        <button
          className="toggleContainer-menuButton mt-0"
          aria-label={isOpen ? 'Close Side Menu' : 'Show side menu'}
          onClick={isOpen ? closeSideMenu : showSideMenu}
        >
          {isOpen ? (
            <ChevronLeftIcon className="w-3 h-3 m-1.5" />
          ) : (
            <ChevronRightIcon className="w-3 h-3 m-1.5" />
          )}
        </button>
      </div>
    </div>
  );
}
