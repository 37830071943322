import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectSearchFilterOptions,
  selectHideSearchResults,
  selectSuggestionsQuery,
} from '../../selectors/searchSelectors';

const resultCountSelector = createSelector(
  selectSearchFilterOptions,
  selectHideSearchResults,
  selectSuggestionsQuery,
  (searchFilterOptions, hideSearchResults, suggestionsQuery) => ({
    searchFilterOptions,
    hideSearchResults,
    suggestionsQuery,
  })
);

const ResultCount: React.FC<{
  numberOfResults: number;
}> = ({ numberOfResults }) => {
  const { searchFilterOptions, hideSearchResults, suggestionsQuery } =
    useSelector(resultCountSelector);
  const { searchTerm } = searchFilterOptions;

  const displaySearchTerm = suggestionsQuery ? suggestionsQuery : searchTerm;

  let countString = hideSearchResults
    ? '0'
    : numberOfResults?.toLocaleString('en-US');
  const resultString = numberOfResults === 1 ? 'result' : 'results';
  const searchTermString =
    !!displaySearchTerm.length && ` for ${displaySearchTerm}`;

  if (!hideSearchResults && numberOfResults === 10000) {
    countString += '+';
  }

  return (
    <h3 className="results-count whitespace-nowrap text-sm font-normal sm:whitespace-normal">
      ({`${countString} ${resultString}`} found{searchTermString})
    </h3>
  );
};

export default ResultCount;
